<template>
  <DefaultLayout>
    <FloatingBox class="sm:w-auto">
      <Spinner v-if="loading" class="block" />
      <span v-else-if="error" class="text-red-700">{{$t('extension.error')}}</span>
      <div v-else class="flex items-center flex-col sm:flex-row">
        <component :is="icon" :size="56" class="m-4 rounded-full p-1 w-14 flex-shrink-0" :class="{
          'bg-green-300': result,
          'bg-yellow-400': !result,
        }"/>

        <div v-if="result" class="p-2 py-4">
          <h3 class="text-lg font-medium mb-1" v-text="$t('extension.result-granted')"></h3>
          <p class="text-sm">
            {{ $t('extension.granted-date', { date: expiresAtFormatted  }) }}
          </p>
        </div>
        <div v-else class="p-2 py-4">
          <h3 class="text-lg font-medium mb-1" v-text="$t('extension.result-denied')"></h3>
          <p class="text-sm">
            {{ $t(`extension.reason-${reason}`) }}
          </p>
        </div>
      </div>
    </FloatingBox>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from '../layouts/Default.vue'
import FloatingBox from '../components/FloatingBox'
import Spinner from '../components/Spinner.vue'
import i18n from '../i18n'

import api from '../api';
import dayjs from 'dayjs';

import CheckCircle from '../icons/CheckCircle.vue'
import ExclamationTriangle from '../icons/ExclamationTriangle.vue'

export default {
  name: 'Search',
  components: {
    DefaultLayout,
    FloatingBox,
    Spinner,
  },
  props: {
    referenceNumber: String,
    barcode: String
  },
  data() {
    return {
      loading: true,
      error: false,
      result: null,
      reason: null,
      expires_at: null,
    }
  },
  computed: {
    expiresAtFormatted() {
      return this.expires_at 
        ? dayjs(this.expires_at).locale(i18n.locale).format('LLL')
        : null
    },
    icon() {
      return this.result ? CheckCircle : ExclamationTriangle
    }
  },
  async created() {
    try {
      const {data: { result, reason, expires_at }} = await api.get(`extension/${this.referenceNumber}/${this.barcode}`)

      this.result = result
      this.reason = reason
      this.expires_at = expires_at
    } catch (error) {
      this.error = true
    } finally {
      this.loading = false
    }
  },
  methods: {
  },
  metaInfo() {
    return {
      title: this.$t('extension.title'),
    }
  } 
};
</script>